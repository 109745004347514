import _ from 'lodash';
import {Topology, PageMap, getLocaleNamespace} from '@wix/wixstores-client-core';
import {IDataResponse} from '../../types/cart';
import {
  SiteStore,
  CartActions,
  IEcomPlatformPublicApi,
  EcomPlatformViewerScriptContext,
} from '@wix/wixstores-client-storefront-sdk';
import {isWorker} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/utils';
import {ICartIconStyleParams, ICtrlProps} from '../../types/app-types';
import {ICart} from '@wix/wixstores-graphql-schema';
import {query as getAppSettingsData} from '../../graphql/getAppSettingsData.graphql';
import {IControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types';
import {PubSubManager} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/PubSubManager/PubSubManager';
import {
  cartCartIconLoaded,
  cartClickOnCartIconToOpenMiniCart,
  clickToViewCartPage,
} from '@wix/bi-logger-ecom-platform-data/v2';
import {EMPTY_CART_GUID, FedopsInteraction, SPECS} from '../../constants';
import {ControllerParams} from '@wix/yoshi-flow-editor';
import {CartModel} from '../models/Cart.model';
import {Cart} from '@wix/ecom_current-cart';

const iconsWithText = [3, 4, 5, 6, 7];

export class CartIconStore {
  private appSettingsPromise?: Promise<any>;
  private readonly cartActions: CartActions;
  private readonly pubSubManager: PubSubManager;
  private appSettings?: {[key: string]: any};
  private styleParams: ICartIconStyleParams;
  private cartModel?: CartModel;

  constructor(
    private readonly siteStore: SiteStore,
    private readonly config: IControllerConfig,
    private readonly setProps: (props: any) => void,
    private readonly reportError: (e: any) => any,
    private readonly translations: ControllerParams['flowAPI']['translations'],
    private readonly fedops: ControllerParams['flowAPI']['fedops'],
    private readonly panoramaClient: ControllerParams['flowAPI']['panoramaClient'],
    private readonly wixCodeApi: ControllerParams['flowAPI']['controllerConfig']['wixCodeApi'],
    private readonly currentCartService?: EcomPlatformViewerScriptContext['currentCartService']
  ) {
    this.pubSubManager = new PubSubManager(this.siteStore.pubSub);
    this.wixCodeApi = wixCodeApi;
    this.cartActions = this.currentCartService!.cartActions;
    this.styleParams = this.config.style.styleParams;

    this.currentCartService!.onChange(() => {
      void this.onUpdated();
    });
  }

  private async onUpdated() {
    /* istanbul ignore else: todo: missing test */
    if (this.siteStore.experiments.enabled(SPECS.CatchErrorsOnCartIcon)) {
      try {
        await this.fetchCartModel();
        this.updateCartCount(this.cartModel!.itemsCount);
      } catch (e) {
        this.reportError(e);
      }
    } else {
      await this.fetchCartModel();
      this.updateCartCount(this.cartModel!.itemsCount);
    }
  }

  public updateCartCount(count: number | undefined): void {
    this.setProps({
      ...this.getCountRelatedProps(count),
    });
  }

  public async init(): Promise<void> {
    const cartPromise: Promise<void> | undefined = this.siteStore.isSSR()
      ? /* istanbul ignore next: todo: missing test */ undefined
      : this.fetchCartModel();

    const isMultilingualNonPrimary = () => {
      const multiLangFields = this.siteStore.getMultiLangFields();
      return !!multiLangFields && !multiLangFields.isPrimaryLanguage;
    };

    const shouldFetchAppSettingsML = isMultilingualNonPrimary();

    const shouldFetchAppSettingsNeededIcon = iconsWithText.includes(this.styleParams.numbers.cartWidgetIcon || 1);

    const shouldFetchAppSettingsExternalIdDefined = !!this.config.externalId;

    const appSettingsPromise =
      shouldFetchAppSettingsML && shouldFetchAppSettingsNeededIcon && shouldFetchAppSettingsExternalIdDefined
        ? this.getAppSettingsData()
        : undefined;

    const isCartIconCssVarsCssOptimizationEnabled = this.siteStore.experiments.enabled(
      SPECS.CartIconCssVarsCssOptimization
    );

    try {
      const [serverResponse, cartLink] = await Promise.all([
        appSettingsPromise,
        this.siteStore.getSectionUrl(PageMap.CART),
      ]);
      const props = {
        ...this.getCountRelatedProps(undefined),
        cartLink: _.get(cartLink, 'url', ''),
        fitToContentHeight: true,
        isInteractive: this.siteStore.isInteractive(),
        isLoaded: true,
        displayText: this.getDisplayText(serverResponse?.widgetSettings),
        triggerFocus: false,
        onFocusTriggered: this.onFocusTriggered,
        isNavigate: !this.shouldOpenMinicartFromSettings(),
        onIconClick: this.onIconClick,
        onAppLoaded: this.onAppLoaded,
        ravenUserContextOverrides: {
          id: this.siteStore.storeId,
          uuid: this.siteStore.uuid,
        },
        isCartIconCssVarsCssOptimizationEnabled,
        fixCartIconOnEditor: this.siteStore.experiments.enabled(SPECS.FixCartIconOnEditor),
      } as ICtrlProps;
      this.setProps(props);

      cartPromise
        ?.then(() => {
          this.fedops.interactionEnded(FedopsInteraction.CART_ICON_LOADED);
          this.setProps({
            ...this.getCountRelatedProps(this.cartModel?.itemsCount),
          });
        })
        .catch((e) => {
          /* istanbul ignore next: todo: missing test */
          if (this.siteStore.experiments.enabled(SPECS.CatchErrorsOnCartIcon)) {
            this.cartModel = CartModel.fromSDK({_id: EMPTY_CART_GUID, lineItems: []} as Cart);
            this.updateCartCount(0);
          }
          this.reportError(e);
        });
    } catch (e) {
      /* istanbul ignore next: todo: missing test */
      this.reportError(e);
    }
  }

  /* istanbul ignore next: todo: missing test */
  public onFocusTriggered = (): void => {
    this.setProps({
      triggerFocus: false,
    });
  };

  public onAppLoaded = (): void => {
    /* istanbul ignore else: todo: missing test */
    if (!isWorker() || this.siteStore.isInteractive()) {
      const shouldReport = this.siteStore.storage.memory.getItem('cartIconLoaded');
      /* istanbul ignore else: todo: missing test */
      if (!shouldReport) {
        this.siteStore.storage.memory.setItem('cartIconLoaded', 'true');
        void this.reportCartIconLoaded();
      }
    }
  };

  private readonly reportCartIconLoaded = async (): Promise<void> => {
    const baseBiParams = {
      isMobileFriendly: this.siteStore.isMobileFriendly,
      navigationClick: this.shouldOpenMinicartFromSettings() ? 'mini cart' : 'cart',
    };

    void this.siteStore.webBiLogger.report(
      cartCartIconLoaded({
        ...baseBiParams,
        cartId: await this.getCartId(),
      })
    );
  };

  public getDisplayText(widgetSettings?: {[key: string]: string}): string {
    const defaultValue = this.translations.t(`CART_ICON_${this.styleParams.numbers.cartWidgetIcon || 1}`);
    let widgetSettingsForLocale = {};
    const multiLangFields = this.siteStore.getMultiLangFields();
    if (multiLangFields && !multiLangFields.isPrimaryLanguage) {
      /* istanbul ignore else: todo: missing test */
      if (widgetSettings) {
        widgetSettingsForLocale = widgetSettings[getLocaleNamespace(multiLangFields.lang)];
        return _.get(widgetSettingsForLocale, 'CART_ICON_TEXT', '') || defaultValue;
      } else if (this.appSettings) {
        widgetSettingsForLocale = this.appSettings[getLocaleNamespace(multiLangFields.lang)];
        return _.get(widgetSettingsForLocale, 'CART_ICON_TEXT', '') || defaultValue;
      } else {
        return defaultValue;
      }
    }
    return (
      _.get(this.appSettings, 'main.CART_ICON_TEXT', '') ||
      _.get(this.config, 'publicData.APP.CART_ICON_TEXT', '') ||
      defaultValue
    );
  }

  /* istanbul ignore next: todo: missing test */
  public updateStyleParams(newStyleParams: ICartIconStyleParams) {
    this.styleParams = newStyleParams;
    this.setProps({
      displayText: this.getDisplayText(),
    });
  }

  /* istanbul ignore next: todo: missing test */
  public updateAppSettings(appSettings: {[key: string]: any}) {
    this.appSettings = appSettings;
    this.setProps({
      displayText: this.getDisplayText(),
    });
  }

  public async getAppSettingsData(): Promise<IDataResponse> {
    /* istanbul ignore next: todo: missing test */
    if (this.appSettingsPromise) {
      return this.appSettingsPromise;
    }

    const postData = {
      query: getAppSettingsData,
      source: 'WixStoresWebClient',
      operationName: 'getAppSettings',
      variables: {externalId: this.config.externalId || /* istanbul ignore next: todo: missing test */ ''},
    };

    this.appSettingsPromise = this.siteStore
      .tryGetGqlAndFallbackToPost(this.siteStore.resolveAbsoluteUrl(`/${Topology.STOREFRONT_GRAPHQL_URL}`), postData)
      .then(({data}) => {
        return {
          widgetSettings: _.get(data, 'appSettings.widgetSettings', {}),
        };
      });

    return this.appSettingsPromise;
  }

  public shouldOpenMinicartFromSettings(): boolean {
    const {iconLink} = this.styleParams.numbers;
    return !iconLink || iconLink === 2;
  }

  private readonly handleNavigateToCart = async (partialBi: object) => {
    const origin = 'cart-icon';

    void this.siteStore.webBiLogger.report(
      clickToViewCartPage({
        ...partialBi,
        origin,
        isNavigateCart: true,
      })
    );

    await this.siteStore.navigate({sectionId: PageMap.CART, queryParams: {origin}}, true);
  };

  private readonly subscribeToMiniCartCloseEvent = () => {
    /* istanbul ignore next: todo: missing test */
    const eventId = this.pubSubManager.subscribe('Minicart.DidClose', () => {
      this.setProps({
        triggerFocus: true,
      });
      /* istanbul ignore next: todo: missing test */
      this.pubSubManager.unsubscribe('Minicart.DidClose', eventId);
    });
  };
  public onIconClick = async (): Promise<void> => {
    const cartId = await this.getCartId();
    const partialBi = {
      cartId,
      itemsCount: this.cartModel?.itemsCount,
      viewMode: this.siteStore.viewMode.toLowerCase(),
    };
    if (this.shouldOpenMinicartFromSettings()) {
      const api: IEcomPlatformPublicApi = await this.wixCodeApi.site.getPublicAPI(
        '1380b703-ce81-ff05-f115-39571d94dfcd'
      );

      try {
        await Promise.resolve(api.cart.showMinicart());
      } catch (err) {
        /* istanbul ignore next: todo: missing test */
        await this.handleNavigateToCart(partialBi);
        /* istanbul ignore next: todo: missing test */
        return;
      }

      void this.siteStore.webBiLogger.report(
        cartClickOnCartIconToOpenMiniCart({
          ...partialBi,
          isNavigateCart: false,
        })
      );

      if (!(await api.cart.hasSideCart())) {
        this.subscribeToMiniCartCloseEvent();
      }
    } else {
      await this.handleNavigateToCart(partialBi);
    }
  };

  /* istanbul ignore next: todo: missing test */
  public unSubscribeAll(): void {
    return this.pubSubManager.unsubscribeAll();
  }

  private async getCartId(): Promise<string | undefined> {
    let id = this.cartModel?.id;

    if (!id) {
      /* istanbul ignore else: todo: missing test */
      if (this.siteStore.experiments.enabled(SPECS.CatchErrorsOnCartIcon)) {
        try {
          await this.fetchCartModel();
          id = this.cartModel?.id;
        } catch (e) {
          /* istanbul ignore next: todo: missing test */
          id = EMPTY_CART_GUID;
        }
      } else {
        await this.fetchCartModel();
        id = this.cartModel?.id;
      }
    }

    return id === EMPTY_CART_GUID ? undefined : id;
  }

  private getCountRelatedProps(count: number | undefined) {
    return {
      count,
      ariaLabelLink: this.translations.t('sr.CART_WIDGET_BUTTON_TEXT', {itemsCount: count}),
    };
  }

  private async fetchCartGQLModel(): Promise<void> {
    const cartGQL = (await this.currentCartService!.getCurrentCartGQL()) as ICart;
    this.cartModel = CartModel.fromGQL(cartGQL);
  }

  private async fetchCartSDKModel(): Promise<void> {
    const cartSDK = await this.currentCartService!.getCurrentCart();
    this.cartModel = CartModel.fromSDK(cartSDK);
  }

  private async fetchCartModel(): Promise<void> {
    const useCurrentCartFromSdk = this.siteStore.experiments.enabled(SPECS.UseCurrentCartFromSdk);
    useCurrentCartFromSdk ? await this.fetchCartSDKModel() : await this.fetchCartGQLModel();
  }

  public async executeWithFedops(interaction: FedopsInteraction, fn: () => Promise<any>): Promise<void> {
    this.fedops.interactionStarted(interaction);
    this.panoramaClient?.transaction(interaction).start();
    await fn();
    this.fedops.interactionEnded(interaction);
    this.panoramaClient?.transaction(interaction).finish();
  }
}
